<template>
  <div id="merchant`-list">
    <!-- app drawer -->
    <merchant-aside
      v-if="aside"
      v-model="aside"
      @changed="fetchMerchants(); aside = false"
    ></merchant-aside>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <v-row
        class="px-2 ma-0"
        justify="start"
      >
        <!-- search -->
        <v-col
          cols="12"
          lg="4"
          md="6"
          class="d-flex justify-start"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder=" Search by name"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn
            color="primary"
            @click="search"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="aside = true"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>New Merchant</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="merchantList"
        :options.sync="tableOptions"
        :server-items-length="merchantTotal"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'merchant-view', params: { id : item.id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ t(item.name) }}
            </router-link>
          </div>
        </template>

        <!-- active? -->
        <template #[`item.active`]="{item}">
          {{ item.active ? 'Yes' : 'No' }}
        </template>

        <!-- active? -->
        <template #[`item.updated_at`]="{item}">
          {{ item.updated_at | dateTime }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'merchant-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDotsVertical, mdiPlus, mdiFileDocumentOutline, mdiDeleteOutline, mdiExportVariant, mdiAccountOutline } from '@mdi/js'

import { ref } from '@vue/composition-api'
import { t } from '@/plugins/i18n'
import { dateTime } from '@core/utils/filter'
import useMerchantsList from './useMerchantsList'
import MerchantAside from '../merchant-resource/MerchantAside.vue'

export default {
  components: { MerchantAside },
  filters: { dateTime },
  setup() {
    const { merchantList, merchantTotal, tableColumns, searchQuery, loading, tableOptions, selectedRows, fetchMerchants, search } = useMerchantsList()

    const aside = ref(false)

    // const statusOptions = [
    //   { title: 'Active', value: 'active' },
    //   { title: 'Inactive', value: 'inactive' },
    // ]

    // const queryOptions = [
    //   { title: 'UUID', value: 'uuid' },
    //   { title: 'Name', value: 'inactive' },
    // ]

    return {
      // table
      merchantList,
      merchantTotal,
      tableColumns,
      tableOptions,
      loading,
      t,

      // search params
      searchQuery,

      aside,
      selectedRows,

      // Methods
      fetchMerchants,
      search,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
